<template>
  <div class="page">
    <div class="content">
      <div class="left">
        <div class="avatar-area">
          <img class="avatar" src="../../static/doctor/avatar.png" />
        </div>
        <div class="nickname">{{ userInfo.nickname }}</div>
        <div class="gender">
          <span>{{ userInfo.gender == 1 ? "男" : "女" }}</span>
        </div>
        <div class="menu">
          <div
            class="menu-item"
            :class="active == 0 ? 'active' : ''"
            @click="selectMenu(0)"
          >
            <b-icon
              class="icon"
              :icon="active == 0 ? 'journal-bookmark-fill' : 'journal-bookmark'"
            ></b-icon>
            我的笔记（{{ noteCount }}）
          </div>
          <div
            class="menu-item"
            :class="active == 1 ? 'active' : ''"
            @click="selectMenu(1)"
          >
            <b-icon class="icon" :icon="active == 1 ? 'heart-fill' : 'heart'"></b-icon>
            我的关注（{{ focusCount }}）
          </div>
          <div
            class="menu-item"
            :class="active == 2 ? 'active' : ''"
            @click="selectMenu(2)"
          >
            <b-icon class="icon" :icon="active == 2 ? 'people-fill' : 'people'"></b-icon>
            我的粉丝（{{ fansCount }}）
          </div>
        </div>
      </div>

      <div class="right">
        <div v-show="active == 0">
          <notelist @setCount="setCount"></notelist>
        </div>
        <div class="current" v-show="active == 1">
          <focus @setCount="setCount"></focus>
        </div>
        <div class="current" v-show="active == 2">
          <fans @setCount="setCount"></fans>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import notelist from "./notelist.vue";
import fans from "./fans.vue";
import focus from "./focus.vue";
export default {
  data() {
    return {
      active: 0,
      noteCount: 0,
      focusCount: 0,
      fansCount: 0,
    };
  },
  components: {
    notelist,
    fans,
    focus,
  },
  computed: {
    userInfo() {
      return JSON.parse(this.$cache.getCache("userInfo"));
    },
  },
  methods: {
    selectMenu(index) {
      this.active = index;
    },
    setCount(option) {
      switch (option.type) {
        case 0:
          this.noteCount = option.val;
          break;
        case 1:
          this.focusCount = option.val;
          break;
        case 2:
          this.fansCount = option.val;
          break;
      }
    },
  },
};
</script>
<style lang="scss" scoped>
@import "./my.scss";
</style>
