<template>
  <div class="fans">
    <b-list-group>
      <b-list-group-item class="list-item" v-for="(item, index) in list" :key="index">
        <div class="before">
          <img class="img" :src="item.touxiang" @error="defaultIMG(index)"/>
        </div>
        <div class="center">
          <div class="title">{{ item.nickname }}</div>
          <div class="desc">
            <span style="margin-right: 20px">笔记：{{ item.science_total }}</span>
            <!-- <span style="margin-right: 20px">关注：</span> -->
            <span>粉丝：{{ item.fans_total }}</span>
          </div>
        </div>
        <div class="end">
          <div class="default" @click="onFocus(item)">取消关注</div>
        </div>
      </b-list-group-item>
    </b-list-group>
    <div v-show="count == 0">
      <Empty></Empty>
    </div>
    <div class="page-toolbar" v-show="count > limit">
      <b-pagination
        v-model="page"
        :total-rows="count"
        :per-page="limit"
        aria-controls="my-table"
      ></b-pagination>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      page: 1,
      limit: 6,
      count: 0,
      list: [],
    };
  },
  computed: {
    id() {
      let data = JSON.parse(this.$cache.getCache("userInfo")).id;
      return data;
    },
  },
  mounted() {
    this.getData()
  },
  watch: {
    page: function() {
      this.getData()
    }
  },
  methods: {
    getData() {
      let data = {
        id: this.id,
        limit: this.limit,
        page: this.page,
      };
      this.$http.attention(data).then((res) => {
        console.log(res);
        let { data, code, msg } = res;
        if (code == 1) {
          data.items.forEach(item => {
            item.touxiang = this.$host + item.avatar
          })
          this.list = data.items;
          this.count = data.total;
          this.setCount()
        } else {
          this.$store.commit("showToast", msg);
        }
      });
    },
    defaultIMG(index) {
      this.list[index].touxiang = require("../../static/my/default_avatar.png")
    },
    onFocus(data) {
      this.$http.setattention({
        id: data.id,
        ident: data.ident
      }).then(res => {
        if(res.code == 1) {
          this.$store.commit("showToast", res.msg)
          setTimeout(() => {
            this.getData()
          }, 2000)
        }else {
          this.$store.commit("showToast", res.msg)
        }
      })
    },
    setCount() {
      return this.$emit("setCount", {type: 1, val: this.count})
    }
  },
};
</script>
<style lang="scss" scoped>
@import "./my.scss";
</style>
