<template>
  <div class="notelist">
    <b-overlay :show="show" class="notelist">
      <div class="my-card" v-for="(item, index) in list" :key="index">
        <div class="my-card-deshad">
          <img
            src="../../static/my/my-notes_ico_delete@2x.png"
            class="deshad-icon"
            @click="deshad(item.id)"
          />
        </div>
        <img class="my-card-img" :src="item.src" @error="defaultIMG(index)" />
        <div class="my-card-foot">{{ item.title }}</div>
        <div class="my-card-tool">
          <img src="../../static/doctor/avatar.png" class="my-card-tool-img" />
          {{ item.user_info ? item.user_info.nickname : "" }}
          <span style="" class="my-card-tool-right">
            <b-icon icon="heart"></b-icon>
            {{ item.like_count }}
          </span>
        </div>
      </div>
    </b-overlay>
    <div v-show="count == 0">
      <Empty></Empty>
    </div>
    <div class="page-toolbar" v-show="count > limit">
      <b-pagination
        v-model="page"
        :total-rows="count"
        :per-page="limit"
        aria-controls="my-table"
      ></b-pagination>
    </div>
    <div @click="showModal = false" v-show="showModal" class="mask"></div>
    <div v-show="showModal" class="custom-modal">
      <div class="custom-modal-header">
        <b-icon class="custom-modal-header-icon" icon="circle"></b-icon> 温馨提示
        <b-icon class="custom-modal-header-icon" icon="circle"></b-icon>
      </div>
      <div class="custom-modal-content">确认删除该条笔记吗？</div>
      <div class="custom-modal-footer">
        <div class="confirm custom-btn" @click="showModal = false">取消</div>
        <div class="cancel custom-btn" @click="confirm">确定</div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      page: 1,
      limit: 9,
      count: 0,
      list: [],
      show: false,
      article_id: "",
      showModal: false,
    };
  },
  computed: {
    id() {
      let data = JSON.parse(this.$cache.getCache("userInfo")).id;
      return data;
    },
  },
  watch: {
    page: function (val) {
      console.log(val);
      this.getData();
    },
  },
  mounted() {
    this.getData();
  },

  methods: {
    getData() {
      let data = {
        user_id: this.id,
        limit: this.limit,
        page: this.page,
      };
      this.show = true;
      this.$http
        .mylist(data)
        .then((res) => {
          this.show = false;
          let { data, code, msg } = res;
          if (code == 1) {
            data.items.forEach((item) => {
              item.src = this.$host + item.resource.split(",")[0];
            });
            this.list = data.items;
            this.count = data.total;
            this.setCount(data.total);
          } else {
            this.$store.commit("showToast", msg);
          }
        })
        .catch(() => {
          this.show = false;
        });
    },
    defaultIMG(index) {
      this.list[index].src = require("../../static/my/empty-image-error.png");
    },
    deshad(id) {
      this.article_id = id;
      this.showModal = true;
    },
    confirm() {
      this.showModal = false;
      this.$http
        .del_article({
          id: this.article_id,
        })
        .then((res) => {
          if (res.code == 1) {
            this.$store.commit("showToast", "删除成功");
            setTimeout(() => {
              this.getData();
            }, 2000);
          } else {
            this.$store.commit("showToast", res.msg);
          }
        });
    },
    setCount(v) {
      return this.$emit("setCount", { type: 0, val: v });
    },
  },
};
</script>
<style lang="scss" scoped>
@import "./my.scss";
.header-custom {
  background: #fd9852;
  color: #fff;
}
</style>
